import React from "react";
import Image from "next/image";
import { usePathname } from "next/navigation";
import TypographyMontserrat from "../../Atoms/TypographyMontserrat";
import { setCookie } from "cookies-next";
import {
  USER_COUNTRY,
  SAUDI_ISO,
  DUBAI_ISO,
} from "../../../public/Constants/EnumConstants";
import {
  countriesArray,
  SELECT_COUNTRY_TEXT,
  POP_UP_HEADING_TEXT,
  COUNTRY_ICON_ALT,
  HOME_ROUTE,
} from "./constants";

const CountrySelectionPopUp = ({ setShowCountryPopUp }) => {
  const pathName = usePathname();
  const handleClick = (isoCode) => {
    setShowCountryPopUp(false);
    setCookie(USER_COUNTRY, isoCode);
    if (isoCode === SAUDI_ISO && pathName === HOME_ROUTE) {
      window.location.href = process.env.HUBSPOT_KSA_WEB_URL;
    }
  };
  return (
    <div className="fixed inset-0 bg-POP_UP_BG bg-opacity-70 z-50 flex justify-center items-center">
      <div className="w-screen lg:w-[650px] p-[18px]  ">
        <div className=" flex flex-col items-center w-full h-full bg-white rounded p-5 lg:p-8">
          <TypographyMontserrat
            text={SELECT_COUNTRY_TEXT}
            customStyle={" !text-2xl md:!text-4xl mb-4"}
          />
          <TypographyMontserrat
            text={POP_UP_HEADING_TEXT}
            fontWeight={"medium"}
            customStyle={" !text-xl md:!text-2xl 	"}
          />
          <div className="w-full h-full flex mt-5 justify-around">
            {countriesArray?.map((country) => (
              <div
                onClick={() => handleClick(country?.code)}
                className=" flex flex-col justify-center items-center w-40 h-40 sm:w-52 sm:h-52 cursor-pointer hover:bg-TESTIMONIAL_BG  "
              >
                <div className=" relative w-full h-24 sm:h-[130px] mb-[10px]">
                  <Image
                    src={country?.logo}
                    alt={COUNTRY_ICON_ALT}
                    fill
                    sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                  />
                </div>
                <TypographyMontserrat
                  fontWeight={"semibold"}
                  customStyle={" !text-xl md:!text-2xl	"}
                  text={country?.name}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountrySelectionPopUp;
