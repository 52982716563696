export const forgotPasswordApi = async (credentials) => {
  const url = `${process.env.BACKEND_URL}core/password-forget/`;
  try {
    const response = await fetch(url, {
      body: JSON.stringify(credentials),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response?.json();
    return data;
  } catch (error) {
    window.newrelic?.noticeError(`error in ${url} api `, {
      attributes: { error: error, credentials: credentials },
    });
    return error?.response?.data;
  }
};
