export const loginApi = async (credentials) => {
  const url = `${process.env.BACKEND_URL}core/token-login/`; // Removed extra space after URL
  try {
    const response = await fetch(url, {
      body: JSON.stringify(credentials),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response?.json();
    return data; // Return the access token from the response
  } catch (error) {
    window.newrelic?.noticeError(`error in ${url} api `, {
      attribute1: { data: credentials, error: error },
    });
    return error?.response?.data;
  }
};

export const signupApi = async (payload) => {
  const url = `${process.env.BACKEND_URL}core/SignUp/`;
  try {
    const response = await fetch(url, {
      body: JSON.stringify(payload),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response?.json();
    return data; // Return the access token from the response
  } catch (error) {
    window.newrelic?.noticeError(`error in ${url} api `, {
      attributes: { data: payload, error: error },
    });
    return error?.response;
  }
};

export const facebookLoginApi = async (credentials) => {
  const url = `${process.env.BACKEND_URL}core/facebook-login/`; // Removed extra space after URL
  try {
    const response = await fetch(url, {
      body: JSON.stringify(credentials),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response?.json();
    return data; // Return the access token from the response
  } catch (error) {
    window.newrelic?.noticeError(`error in ${url} api `, {
      attribute1: { data: JSON.stringify(error) },
    });
    return error?.response?.data;
  }
};
