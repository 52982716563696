import React from "react";
import { ToastContainer } from "react-toastify";
import { contextClass, contextIcons, TOAST_TIME } from "./constants";
import "react-toastify/dist/ReactToastify.css";
import { BODY_CLASS, BOTTOM_CENTER, TOAST_CONTAINER_CLASS } from "./styles";
import { DEFAULT } from "../../../public/Constants/EnumConstants";

const CustomToastContainer = () => {
  return (
    <ToastContainer
      toastClassName={({ type }) => contextClass[type] + TOAST_CONTAINER_CLASS}
      position={BOTTOM_CENTER}
      icon={({ type, icon }) => (
        <img src={type === DEFAULT ? icon : contextIcons[type]} />
      )}
      bodyClassName={() => BODY_CLASS}
      closeButton={false}
      autoClose={TOAST_TIME}
      hideProgressBar
    />
  );
};

export default CustomToastContainer;
