import { SaudiIcon, UAEIcon } from "../../../public/Theme";

export const SELECT_COUNTRY_TEXT = "Select your country";
export const POP_UP_HEADING_TEXT = "Your WearThat experience starts here!";
export const COUNTRY_ICON_ALT = "country-icon";

export const HOME_ROUTE = "/";

export const countriesArray = [
  {
    name: "UAE",
    code: "AE",
    logo: UAEIcon,
  },
  {
    name: "SAUDI",
    code: "SA",
    logo: SaudiIcon,
  },
];
